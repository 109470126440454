<template>
  <div>
    
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;   position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>
    
    <section v-if="!$route.params.articleSlug">
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="col text-center">
            <div class="news-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white">
                  <h1 class="display-2"><strong>News & Insights</strong></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="this.$route.params.articleSlug">
      <div class="container" v-for="item in newsFeed.items" v-if="$route.params.articleSlug == item.app_link">
        <div class="row">
          <div class="col-md-12">
            <img :src="item.image" class="img img-fluid" />
            <h2 class="mt-4 mb-4" v-html="item.title"></h2>
            <div v-html="item.content_html" class="article-html"></div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="!loading">
      <div class="container mt-4 bg-light p-3" v-for="item in newsFeed.items" v-if="item.tags[0] === feedName">
        <div class="row">
          <div class="col-md-4">
            <img :src="item.image" class="img img-fluid">
          </div>
          <div class="col-md-8">
              <h2>{{ item.title }}</h2>
              <p><small><strong>Published on {{ new Date(item.date_published).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }) }}</strong></small></p>
              <p>{{ item.content_text.slice(0, item.content_text.split(" ").slice(0, 50).join(" ").length) }} ... </p>
              <a class="btn filter-btn" :href="'/news/' + item.app_link">Read more</a>
          </div>
        </div>
      </div>
    </section>
    
    <div class="mt-5"></div>

    <Footer />
  
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'
import axios from 'axios'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
    this.fetchNewsFeed()
  },
  data() {
    return {
      feedName: 'winelands',
      newsFeed: [],
      loading: true
    }
  },
  methods: {
    fetchNewsFeed: async function () {
      this.loading = true;
      try {
        const response = await axios.get('https://luxury-dev.chaseveritt.co.za/news/feed/json/', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.newsFeed = response.data;
        this.newsFeed.items.forEach(item => {
          const urlParts = item.url.split('/');
          const slug = urlParts[urlParts.length - 2];
          item.app_link = slug;
        });
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    }
  }
}
</script>
<style scoped>
.article-html img {
  width: 100% !important;
}
</style>